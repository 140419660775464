<template>
<div class="container">
    <div class="top-panel bg-s-cover">
        <mobile-header title="自动贴膜电子兑换券" />
        <br/>
        <br/>
        <br/>
        <br/>
        <div class="code-box bg-s-cover center">{{couponCode}}</div>
        <!-- <div class="qr-code-box bg-s-cover flex flex-a-i-center flex-j-c-center">
            <div class="qr-code bg-s-cover" :style="{backgroundImage: `url(./images/test/share_qr_code.png)`}"></div>
        </div> -->
        <div class="tips center" v-if="couponValid">让你的好友也体验一下吧</div>
        <div class="tips center" v-if="!couponValid">{{coupon.err}}</div>
    </div>
    <br/>
    <br/>
    <div class="instructions" v-if="couponValid">
        <div class="instructions-title">使用说明：</div>
        <div class="content">
            <p>1.在贴膜机中选择好膜类型后，扫码进入支付页面，输入兑换码即可使用；</p>
            <p>2.有效时间：<span>{{getDate(coupon.data.start_date)}}</span>  至  <span>{{getDate(coupon.data.end_date)}}</span></p>
            <p>3.最大兑换次数：<span>{{coupon.data.use_limit}}</span>，剩余兑换次数：<span>{{coupon.data.coupon_remained_count}}</span></p>
        </div>
    </div>
    <!-- <div class="btn share-btn bg-s-cover center" v-if="couponValid" @click="shareCoupon">分享</div> -->
    <!-- <div class="btn close-btn bg-s-cover center" v-if="!couponValid" @click="closeWindow">关闭</div> -->
    <div class="btn close-btn bg-s-cover center" @click="closeWindow">关闭</div>
</div>
</template>

<script>
import MobileHeader from '@/components/MobileHeader.vue'
import {mapState} from 'vuex';
import {REQUEST_ORDER_INFO,REQUEST_COUPON_INFO} from '@/store/mutation-types';
import { getUrlKey } from "@/lib/util";
import moment from 'moment';
import wx from 'weixin-js-sdk';
export default {
    data() {
        return {
            client: localStorage.getItem('client'),
            couponCode: getUrlKey('couponCode'),
            couponValid: false
        }
    },
    components: {
        MobileHeader
    },
    created: async function() {
        await this.checkCoupon();
    },
    computed: {
        ...mapState({
            coupon: state => state.Coupon,
        })
    },
    methods: {
        async checkCoupon() {
            if (this.couponCode != null || this.couponCode != ''){
                await this.$store.dispatch(REQUEST_COUPON_INFO, {
                    couponCode: this.couponCode,
                    checkDate: moment().format('YYYY-MM-DD HH:mm:ss')
                });
                // if (this.coupon.data !={}) {
                if(JSON.stringify(this.coupon.data) == '{}'){
                    // console.log(this.coupon.err);
                    alert(this.coupon.err);
                }
                else {
                    this.couponValid = true;
                }
            }
        },
        closeWindow(){
            if(this.client == 'wechat'){
                wx.closeWindow();
                // WeixinJSBridge.call('closeWindow');//微信 
            }
            else if (this.client == 'alipay') {
                AlipayJSBridge.call('closeWebview'); //支付宝 关闭窗口（别名）
            }
            else {
                window.cloce();
            }
        },
        getDate(originDte){
             return moment(originDte).format('YYYY-MM-DD');
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    $imgBoxHeight: 34.67vw;

    .top-panel {
        width: 100%;
        // height: 103.34vw;
        height: 80vw;
        background-image: url(~@/assets/mobile/share/top_panel.png);
        position: relative;

        .code-box {
            width: 70.67vw;
            // height: 11.33vw;
            height: 11.33vw;
            line-height: 11.33vw;
            // font-size: 6.4vw;
            font-size: 8vw;
            font-weight: 600;
            color: #ffffff;
            background-image: url(~@/assets/mobile/share/code_bg.png);
            margin: 9.33vw auto 0;
        }

        // .qr-code-box {
        //     width: 46.8vw;
        //     height: 46.67vw;
        //     background-image: url(~@/assets/mobile/share/qr_code_box.png);
        //     margin: 5.33vw auto 0;

        //     .qr-code {
        //         width: 40vw;
        //         height: 40vw;
        //     }
        // }
        
        .tips {
            // font-size: 4.27vw;
            font-size: 5vw;
            line-height: 4.27vw;
            color: #ffffff;
            margin-top: 5.33vw;
        }
    }

    .instructions {
        padding: 6.67vw 8vw 0;

        .instructions-title {
            font-size: 4.27vw;
            line-height: 4.27vw;
        }
        
        .content {
            margin-top: 3.87vw;
            font-size: 3.73vw;
            line-height: 3.87vw;
            color: #666666;

            p + p {
                margin-top: 3.2vw;
            }
        }
    }

    .btn {
        width: 84vw;
        height: 10.67vw;
        font-size: 4.27vw;
        line-height: 10.67vw;
    }

    .share-btn {
        color: #ffffff;
        background-image: url(~@/assets/mobile/pay_success/share_btn.png);
        margin: 6.67vw auto 0;
    }

    .close-btn {
        // background-image: url(~@/assets/mobile/pay_success/complete_btn.png);
        background-image: url(~@/assets/mobile/pay_success/share_btn.png);
        margin: 6.67vw auto 0;
    }

}
</style>
