var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "top-panel bg-s-cover" },
      [
        _c("mobile-header", { attrs: { title: "自动贴膜电子兑换券" } }),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("div", { staticClass: "code-box bg-s-cover center" }, [
          _vm._v(_vm._s(_vm.couponCode))
        ]),
        _vm.couponValid
          ? _c("div", { staticClass: "tips center" }, [
              _vm._v("让你的好友也体验一下吧")
            ])
          : _vm._e(),
        !_vm.couponValid
          ? _c("div", { staticClass: "tips center" }, [
              _vm._v(_vm._s(_vm.coupon.err))
            ])
          : _vm._e()
      ],
      1
    ),
    _c("br"),
    _c("br"),
    _vm.couponValid
      ? _c("div", { staticClass: "instructions" }, [
          _c("div", { staticClass: "instructions-title" }, [
            _vm._v("使用说明：")
          ]),
          _c("div", { staticClass: "content" }, [
            _c("p", [
              _vm._v(
                "1.在贴膜机中选择好膜类型后，扫码进入支付页面，输入兑换码即可使用；"
              )
            ]),
            _c("p", [
              _vm._v("2.有效时间："),
              _c("span", [
                _vm._v(_vm._s(_vm.getDate(_vm.coupon.data.start_date)))
              ]),
              _vm._v("  至  "),
              _c("span", [
                _vm._v(_vm._s(_vm.getDate(_vm.coupon.data.end_date)))
              ])
            ]),
            _c("p", [
              _vm._v("3.最大兑换次数："),
              _c("span", [_vm._v(_vm._s(_vm.coupon.data.use_limit))]),
              _vm._v("，剩余兑换次数："),
              _c("span", [
                _vm._v(_vm._s(_vm.coupon.data.coupon_remained_count))
              ])
            ])
          ])
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "btn close-btn bg-s-cover center",
        on: { click: _vm.closeWindow }
      },
      [_vm._v("关闭")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }